import Footer from './Com/Footer/Footer';
import Header from './Com/Header/Header';
import Home from './Com/Home/Home';

function App() {
  return (
    <>
      <Header />
      <Home />
      <Footer/>
    </>
  );
}

export default App;
