import React from 'react'
import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

const Header = () => {
    const toggleSubMenu = (event, menuId) => {
        event.preventDefault(); // Prevent default anchor behavior
        const subMenu = document.getElementById(menuId);
        if (subMenu) {
            subMenu.style.display = subMenu.style.display === 'none' ? 'block' : 'none';
        }
    };

    return (
        <header>
            <div className="container ">
                <div className="row">
                    <div className="col-12 pt-3 ">
                        <img className='header-logo' src="img/logo.png" alt="" />

                        <div className="offcanvas offcanvas-start" id="demo">
                            <div className="offcanvas-header">
                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas"></button>
                            </div>
                            <div className="offcanvas-body">
                                <div className="sidebar">
                                    <a href="no">HOME</a>
                                    <a href="no">ABOUT US</a>
                                    <a href="no" onClick={(event) => toggleSubMenu(event, 'services')}>
                                        SERVICES -
                                    </a>
                                    <div className="sub-menu" id="services">
                                        <a href="no">Services</a>
                                        <a href="no">Digital Marketing</a>
                                        <a href="no">UI UX Design</a>
                                        <a href="no">Branding</a>
                                        <a href="no">Development</a>
                                    </div>
                                    <a href="no">PROJECTS +</a>
                                    <a href="no">BLOGS +</a>
                                    <a href="no">CONTACT US</a>
                                </div>
                            </div>
                        </div>

                        <div className="header-part-right float-end ">
                            {/* <button className="me-3 header-bars float-start" type="button" data-bs-toggle="offcanvas" data-bs-target="#demo">
                                <FontAwesomeIcon className='header-bars-icon' icon={faBars} />
                            </button> */}

                            <a href="https://web.whatsapp.com/"><button className="header-contact" type="button">
                                <FontAwesomeIcon className='header-whatapp-icon' icon={faWhatsapp} />
                            </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header;
