import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import { Pagination } from 'swiper/modules';

import './Home.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import Marquee from 'react-fast-marquee';

const Home = () => {
  return (
    <>
      {/* Home-section section start */}

      <section className='container Home-section'>

        <img className='Home-section-im1' src="img/home-bg-1.png" alt="" />
        <img className='Home-section-im2' src="img/home-bg-2.png" alt="" />

        <div className="container SOME-BRILLIANT-box">
          <div className="row">
            <div className="d-flex align-items-center justify-content-center SOME-BRILLIANT-MINDS0-MAIN">
              <h2 className="hero-title SOME-BRILLIANT-MINDS-text">SOME BRILLIANT MINDS</h2>
              <img src="img/brain.png" className="brain-img" alt="brain" />
            </div>
          </div>
        </div>

        <div className="container WITH-BRILLIANT-box">
          <div className="row">
            <div className="d-flex align-items-center justify-content-center SOME-BRILLIANT-MINDS0-MAIN">
              <h2 className="hero-title">WITH</h2>
              <img src="img/light.png" className="brain-img" alt="brain" />
              <h2 className="hero-title">BRILLIANT</h2>
              <p className='WITH-BRILLIANT-box-p11'>Empowering Brands to Thrive <br />
                Your Vision, Our Expertise, Endless Possibilities</p>
            </div>
          </div>
        </div>

        <div className="container IDEAS-box">
          <div className="row ">
            <div className="d-flex align-items-center justify-content-center SOME-BRILLIANT-MINDS0-MAIN">
              <img src="img/line.png" className="IDEAS-box-img" alt="brain" />
              <h2 className="hero-title">IDEAS</h2>
              <img src="img/arrow.png" className="brain-img" alt="brain" />

            </div>
          </div>
        </div>


        <div className="container mt-5 p-0">
          <div className="row">
            <div className="text-end ">
              <button className="cus-btn-2">
                <span className="btn-text">Get Started</span>
                <span className="btn-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width={23} height="36" viewBox="0 0 23 36" fill="none">
                    <path d="M4.33929 3.70215L16.9521 16.315C17.8828 17.2456 17.8828 18.7544 16.9521 19.685L4.33929 32.2979" stroke="black" strokeWidth="4.76596"></path>
                  </svg>
                </span>
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* home-section section end */}


      {/* home-section-2 section start */}

      <section className='home-section-2'>

        <div className="container-fluid">
          <div className="row">
            <Marquee className='logo-sec'>
              <h3 className='fs-1 mt-2 fw-bold text-white float-start ms-4'>LET'S TALK</h3>
              <img className='float-start ms-4' src="img/marq-star.png" alt="" />
              <h3 className='fs-1 mt-2 fw-bold text-white float-start ms-4'>DIXX@AGENCY.COM</h3>
              <img className='float-start ms-4' src="img/marq-star.png" alt="" />
              <h3 className='fs-1 mt-2 fw-bold text-white float-start ms-4'>LET'S TALK</h3>
              <img className='float-start ms-4' src="img/marq-star.png" alt="" />
              <h3 className='fs-1 mt-2 fw-bold text-white ms-4 me-4'>DIXX@AGENCY.COM</h3>
            </Marquee>


          </div>
        </div>

        <div className="container mt-5 pt-3">
          <div className="row">
            <div className="col-12">
              <img className='float-start mt-5 marq-star-img-our-team' src="img/marq-star.png" alt="" />

              <div className="text-center mb-64">
                <div className="images-block">
                  <img src="img/user-1.png" alt="User 1" />
                  <img src="img/user-2.png" alt="User 2" />
                  <img src="img/user-3.png" alt="User 3" />
                </div>
                <h4 className="Our-Team-text">Our Team</h4>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="our-team-text-box">
              <h3>#1 Digital Agency for Design, Marketing & Development. We
                provide sleek and impactful solutions tailored to your needs,
                including website design, branding, digital marketing, SEO, and
                custom development.</h3>
            </div>

            <div className="text-center">
              <FontAwesomeIcon className='float-start mt-5 fs-1 text-danger' icon={faStar} />
              <button className="cus-btn"><span className="btn-text">Discover More</span></button>
            </div>


          </div>
        </div>

      </section>
      {/* home-section-2 section end */}

      {/*impression-sec section start */}

      <section className="impression-sec  mt-5 pt-5">
        <div className="container ">
          <div className="heading mb-64">
            <h5 className="color-sec fw-600 mb-8">LET US SHOW YOU!</h5>
            <img className="title-icon" src="img/title-vector.png" alt="" />
            <h2 className="light-black">WE DESIGN NOT JUST<br />QUICK <span className="color-sec">IMPRESSIONS</span> </h2>
          </div>

          <div className="row row-gap-4">
            <div className="col-xxl-6 col-lg-7 mt-5">
              <div className="block-1 ">
                <div className="title-block mb-48">
                  <div className="d-flex align-items-center gap-24">
                    <h5 className="fw-600">01</h5>
                    <img className="icon-vec" src="img/icon-1.png" alt="" />
                    <h3 className="">Digital Marketing</h3>
                  </div>
                  <img className="arrow-vector rotate" src="img/imression-arrow.png" alt="arrow" />
                </div>
                <div className="p-content">
                  <p className="mb-48">Lorem ipsum dolor sit amet consectetur. Proin est nullam euismod pulvinar in nullam
                    lacus tellus semper. Mauris elementum consequat aliquam diam mauris interdum. Amet in venenatis.</p>
                </div>
              </div>
              <div className="hr-line mb-24 bg-light-gray"></div>
              <div className="block-2">
                <div className="title-block mb-48 pt-48">
                  <div className="d-flex align-items-center gap-24">
                    <h5 className="fw-600">02</h5>
                    <img className="icon-vec" src="img/icon-2.png" alt="" />
                    <h3 className="">UI UX Design</h3>
                  </div>
                  <img className="arrow-vector" src="img/imression-arrow.png" alt="arrow" />
                </div>
                <div className="p-content style=display none" >
                </div>
              </div>
              <div className="hr-line mb-24 bg-light-gray"></div>
              <div className="block-3">
                <div className="title-block mb-48 pt-48">
                  <div className="d-flex align-items-center gap-24">
                    <h5 className="fw-600">03</h5>
                    <img className="icon-vec" src="img/icon-3.png" alt="" />
                    <h3 className="">Branding</h3>
                  </div>
                  <img className="arrow-vector" src="img/imression-arrow.png" alt="arrow" />
                </div>
                <div className="p-content  style=display none" >
                </div>
              </div>
              <div className="hr-line mb-24 bg-light-gray"></div>
              <div className="block-4">
                <div className="title-block mb-48 pt-48">
                  <div className="d-flex align-items-center gap-24">
                    <h5 className="fw-600">04</h5>
                    <img className="icon-vec" src="img/icon-4.png" alt="" />
                    <h3 className="">Development</h3>
                  </div>
                  <img className="arrow-vector" src="img/imression-arrow.png" alt="arrow" />
                </div>
                <div className="p-content style=display none" >
                </div>
              </div>
              <div className="hr-line bg-light-gray"></div>
            </div>



            <div className="col-xxl-6 col-lg-5 mt-5">
              <div className="img-block">
                <div className="block-img-1" >
                  <img className="br-32 " src="img/impression-img-1.jpg" alt="" />
                </div>
              </div>
            </div>

          </div>
        </div>

        <div className="impression-sec-marquee-box">
          <Marquee>
            <img className='ms-5 ps-5' src="img/marq-mid-img-1.png" alt="" />
            <img className='ms-5 ps-5' src="img/marq-mid-img-2.png" alt="" />
            <img className='ms-5 ps-5' src="img/marq-mid-img-3.png" alt="" />
            <img className='ms-5 ps-5' src="img/marq-mid-img-4.png" alt="" />
            <img className='ms-5 ps-5' src="img/marq-mid-img-5.png" alt="" />
          </Marquee>
        </div>

      </section>
      {/* impression-sec section end */}


      {/* project-sec section start */}

      <section className='project-sec '>
        <div className="container">
          <div className="heading mb-24">
            <h5 className="color-sec fw-bold mb-8 ">LET US SHOW YOU!</h5>
            <img className="title-icon" src="img/title-vector.png" alt="" />
            <h2 className="light-black fw-bold ">WE CREATE AND<span className="color-sec">INNOVATE</span> </h2>
          </div>

          <div>
            <div className="mb-48">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="project-tab" data-bs-toggle="tab" data-bs-target="#project" type="button" role="tab" aria-controls="project" aria-selected="false">ALL PROJECTS</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="marketing-tab" data-bs-toggle="tab" data-bs-target="#marketing" type="button" role="tab" aria-controls="marketing" aria-selected="false">DIGITAL MARKETING</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="branding-tab" data-bs-toggle="tab" data-bs-target="#branding" type="button" role="tab" aria-controls="branding" aria-selected="false">BRANDING</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link" id="dev-tab" data-bs-toggle="tab" data-bs-target="#dev" type="button" role="tab" aria-controls="dev" aria-selected="false">DEVELOPMENT</button>
                </li>
                <li className="nav-item" role="presentation">
                  <button className="nav-link active" id="design-tab" data-bs-toggle="tab" data-bs-target="#design" type="button" role="tab" aria-controls="design" aria-selected="true">UI DESIGN</button>
                </li>
              </ul>
            </div>

            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade active show"
                id="project"
                role="tabpanel"
                aria-labelledby="project-tab"
              >
                <div className="row row-gap-4 align-items-center">
                  <div className="col-md-5">
                    <div className="img-block">
                      <img src="img/project-img-1.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Branding For Stationary
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="img-block">
                      <img src="img/project-img-2.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Full Brand Branding
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="img-block">
                      <img src="img/project-img-3.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Marketing Final
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="img-block">
                      <img src="img/project-img-4.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Digital Design{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="marketing"
                role="tabpanel"
                aria-labelledby="marketing-tab"
              >
                <div className="row row-gap-4 align-items-center">
                  <div className="col-md-8">
                    <div className="img-block">
                      <img src="img/project-img-3.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Marketing Final
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="img-block">
                      <img src="img/project-img-4.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Digital Design{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="img-block">
                      <img src="img/project-img-1.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Branding For Stationary
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="img-block">
                      <img src="img/project-img-2.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Full Brand Branding
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="branding"
                role="tabpanel"
                aria-labelledby="branding-tab"
              >
                <div className="row row-gap-4 align-items-center">
                  <div className="col-md-4">
                    <div className="img-block">
                      <img src="img/project-img-4.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Digital Design{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="img-block">
                      <img src="img/project-img-3.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Marketing Final
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="img-block">
                      <img src="img/project-img-2.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Full Brand Branding
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="img-block">
                      <img src="img/project-img-1.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Branding For Stationary
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="dev"
                role="tabpanel"
                aria-labelledby="dev-tab"
              >
                <div className="row row-gap-4 align-items-center">
                  <div className="col-md-7">
                    <div className="img-block">
                      <img src="img/project-img-2.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Full Brand Branding
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="img-block">
                      <img src="img/project-img-1.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Branding For Stationary
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="img-block">
                      <img src="img/project-img-4.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Digital Design{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <div className="img-block">
                      <img src="img/project-img-3.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Marketing Final
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="design"
                role="tabpanel"
                aria-labelledby="design-tab"
              >
                <div className="row row-gap-4 align-items-center">
                  <div className="col-md-5">
                    <div className="img-block">
                      <img src="img/project-img-1.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Branding For Stationary
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-7">
                    <div className="img-block">
                      <img src="img/project-img-2.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Full Brand Branding
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-8">
                    <div className="img-block">
                      <img src="img/project-img-3.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Marketing Final
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="img-block">
                      <img src="img/project-img-4.jpg" alt="" />
                      <div className="img-content">
                        <button className="label-block">
                          Digital Design{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* project-sec section end */}



      {/*  OUR-HAPPY-CLIENTSsection start */}

      <section className='project-sec OUR-HAPPY-CLIENTS  testemonial-sec py-80 container'>
        <div className="">
          <div className="heading mt-5">
            <h5 className="color-sec fw-600 mb-8 fw-bold">LET US SHOW YOU!</h5>
            <img className="title-icon" src="img/title-vector.png" alt="" />
            <h2 className="light-black fw-bold">HEAR FROM OUR <span className="color-ter">HAPPY </span>CLIENTS </h2>
          </div>

          <Swiper id='swiper-1'
            slidesPerView={1}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 4,
                spaceBetween: 40,
              },
              1024: {
                slidesPerView: 5,
                spaceBetween: 50,
              },
            }}
            modules={[Pagination]}
            className="mySwiper"
          >
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v2">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v1">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v3">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v2">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-1.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v1">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-2.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
            <SwiperSlide id='swiper-Slide-1'>
              <div className="testimonial-block v3">
                <div className="title-img d-flex align-items-end justify-xl-content-between mb-32 flex-wrap">
                  <div className="shape-block">
                    <img className="shape-img" src="img/shape.png" alt="" />
                    <div className="user-img">
                      <img src="img/testimonial-user-3.jpg" alt="" />
                    </div>
                  </div>
                  <div className="d-flex flex-column justify-content-end">
                    <h5 className="fw-600 white mb-4p">Alyssa Funk DDS</h5>
                    <h6 className="fw-400 lightest-gray">Associate</h6>
                  </div>
                </div>
                <h5 className="fw-500 lightest-gray">"Dixx redefined our marketing! Their expertise brought
                  clarity and
                  measurable success to our product."</h5>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>

      {/*  OUR-HAPPY-CLIENTS section end */}


      {/* growth-sec section start */}

      <section className="growth-sec ">
        <div className="container-fluid ">
          <div className="d-sm-flex d-block text-sm-left text-center align-items-center justify-content-center gap-24 mb-48">
            <div className="star-1">
              <div className="star-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={221}
                  height={220}
                  viewBox="0 0 221 220"
                  fill="none"
                >
                  <g clipPath="url(#clip0_11765_296)">
                    <path
                      d="M220.5 110C220.5 92.2836 190.86 77.176 149.214 71.2864C143.324 29.6401 128.216 0 110.5 0C92.7839 0 77.6765 29.6398 71.7864 71.2864C30.1403 77.176 0.5 92.2841 0.5 110C0.5 127.716 30.1401 142.824 71.7864 148.714C77.676 190.36 92.7836 220 110.5 220C128.216 220 143.324 190.36 149.214 148.714C190.86 142.824 220.5 127.716 220.5 110Z"
                      fill="#FFAB38"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_11765_296">
                      <rect x="0.5" width={220} height={220} rx={110} fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="">
              <h1 className="fw-600 growth-sec-h1">
                READY TO ELEVATE YOUR
                <br />
                BRAND'S{" "}
                <img
                  className="goal-vector"
                  src="img/goal.png"
                  alt=""
                />{" "}
                IMPACT AND
                <br />
                DRIVE SERIOUS GROWTH?
              </h1>
            </div>
            <div className="star-2 d-sm-block d-none">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={221}
                height={218}
                viewBox="0 0 221 218"
                fill="none"
              >
                <g clipPath="url(#clip0_11765_299)">
                  <path
                    d="M220.5 83.3673L187.676 26.456C169.809 36.6199 153.013 48.5647 137.545 62.1084C141.528 41.927 143.464 21.3943 143.324 0.823242H77.6762C77.5358 21.3943 79.472 41.927 83.4552 62.1084C67.9868 48.5647 51.1911 36.6199 33.3239 26.456L0.5 83.3673C18.2272 93.774 36.9591 102.362 56.4105 109C36.9589 115.638 18.2269 124.225 0.5 134.633L33.3239 191.543C51.1915 181.38 67.9872 169.435 83.4552 155.891C79.4722 176.072 77.536 196.605 77.6762 217.177H143.324C143.464 196.605 141.528 176.072 137.545 155.891C153.013 169.435 169.809 181.38 187.676 191.543L220.5 134.633C202.773 124.225 184.041 115.638 164.59 109C184.041 102.362 202.773 93.7741 220.5 83.3673Z"
                    fill="#85A9E3"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_11765_299">
                    <rect
                      x="0.5"
                      y="0.823242"
                      width={220}
                      height="216.354"
                      rx="108.177"
                      fill="white"
                    />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="text-center">
            <button className="cus-btn" >
              <span className="btn-text">Contact Us</span>
            </button>
          </div>
        </div>
      </section>

      {/*  growth-sec section end */}

      {/* creative-user section start */}

      <section className="creative-user-sec py-80">
        <div className="container">
          <div className="heading mb-48">
            <h5 className="color-sec fw-600 mb-8">LET US SHOW YOU!</h5>
            <img className="title-icon" src="assets/media/vector/title-vector.png" alt="" />
            <h2 className="light-black">THE <span className="color-ter"> CREATIVES</span> BEHIND THE SCENES </h2>
          </div>
          <div className="row row-gap-4 align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="user-block">
                <div className="img-block">
                  <img className="br-32 w-100" src="img/usert-1.jpg" alt="" />
                </div>
                <div className="user-content">
                  <div className="">
                    <p className="h5 fw-500 mb-8 hover-content light-black">Colleen Bernhard</p>
                    <p className="">Internal Security Coordinator</p>
                  </div>
                  <div className="d-flex gap-12 align-items-center">
                    <img className="links-icon" src="img/bol.png" alt="" />
                    <img className="links-icon" src="img/linkedin.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="user-block">
                <div className="img-block">
                  <img className="br-32 w-100" src="img/usert-2.jpg" alt="" />
                </div>
                <div className="user-content">
                  <div className="">
                    <p className="h5 fw-500 mb-8 hover-content light-black">Jessie Simonis DVM</p>
                    <p className="">Senior Functionality Manager</p>
                  </div>
                  <div className="d-flex gap-12 align-items-center">
                    <img className="links-icon" src="img/bol.png" alt="" />
                    <img className="links-icon" src="img/linkedin.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="user-block">
                <div className="img-block">
                  <img className="br-32 w-100" src="img/usert-3.jpg" alt="" />
                </div>
                <div className="user-content">
                  <div className="">
                    <p className="h5 fw-500 mb-8 hover-content light-black">Bobby Carter</p>
                    <p className="">Legacy Communications </p>
                  </div>
                  <div className="d-flex gap-12 align-items-center">
                    <img className="links-icon" src="img/bol.png" alt="" />
                    <img className="links-icon" src="img/linkedin.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* creative-user section end */}


      {/* services-detail-sec section start */}

      <section className="services-detail-sec py-80">
        <div className="container">
          <h4 className="fw-600 light-black mb-16 fw-bold">What We Do</h4>
          <h6 className="mb-32 mb-3">Social Media Marketing (SMM) involves a comprehensive approach to
            building and maintaining your brand's presence across social platforms. This strategy not only
            enhances visibility and engagement but also helps in nurturing customer relationships and
            driving business growth. By leveraging the unique features of each social media platform, we
            ensure your brand connects with its target audience effectively and consistently.</h6>
          <div className="row row-gap-4 align-items-center mb-48">
            <div className="col-lg-4 col-md-6">
              <div className="label l1">
                <h5 className="fw-600 white">Strategy Development</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="label l2">
                <h5 className="fw-600 white">Content Creation</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="label l3">
                <h5 className="fw-600 white">Platform Mangement</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="label l4">
                <h5 className="fw-600 white">Influence Partnership</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="label l5">
                <h5 className="fw-600 white">Paid Advertising</h5>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="label l6">
                <h5 className="fw-600 white">Analytics &amp; Reporting</h5>
              </div>
            </div>
          </div>
        </div>

      </section >

      {/* services-detail-sec section end */}


      {/* blog-sec section start */}

      <section className="blog-sec ">
        <div className="container ">
          <div className="heading mb-48">
            <h5 className="color-sec fw-600 mb-8">LET US SHOW YOU!</h5>
            <img
              className="title-icon"
              src="img/title-vector.png"
              alt=""
            />
            <h2 className="light-black fw-bold">Behind the Digital Curtain</h2>
          </div>
          <div className="row row-gap-4 align-items-center">
            <div className="col-lg-4 col-md-6">
              <div className="blog-1">
                <div className="blog-content">
                  <div className="d-flex align-items-center gap-16">
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector"
                        src="img/calender.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">12 January, 2024</p>
                    </div>
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector"
                        src="img/user.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">Emilia Clove</p>
                    </div>
                  </div>
                  <div className="">
                    <a
                      href="blog-detail.html"
                      className="h5 fw-600 hover-content mb-20 "
                    >
                      The Rise of Personalization in Technology.
                    </a>
                    <p className='mt-2 mb-0' >
                      technology adapts to our individual needs and
                      <br />
                      preferences- Explore how artificial.
                    </p>
                  </div>
                </div>
                <div className="img-content">
                  <div className="blog-shape">
                    <img
                      className="shape"
                      src="img/blog-shape.png"
                      alt="shape"
                    />
                    <button className="blog-btn">
                      <span className="btn-text">Read More</span>
                    </button>
                  </div>
                  <a href="blog-detail.html" className="img-wrapper w-100">
                    <img
                      className="w-100 blog-img mt-1"
                      src="img/img-1.jpg"
                      alt="img-1"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-1">
                <div className="blog-content">
                  <div className="d-flex align-items-center gap-16">
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector"
                        src="img/calender.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">12 April, 2024</p>
                    </div>
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector"
                        src="img/user.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">Emilia Clove</p>
                    </div>
                  </div>
                  <div className="">
                    <a
                      href="blog-detail.html"
                      className="h5 fw-600 hover-content mb-20"
                    >
                      The Evolution of Personalized Technology
                    </a>
                    <p className='mt-2 mb-0' >
                      Personalized Tech: Exploring the Role of Artificial Intelligence
                    </p>
                  </div>
                </div>
                <div className="img-content">
                  <div className="blog-shape">
                    <img
                      className="shape"
                      src="img/blog-shape.png"
                      alt="shape"
                    />
                    <button className="blog-btn">
                      <span className="btn-text">Read More</span>
                    </button>
                  </div>
                  <a href="blog-detail.html" className="img-wrapper w-100">
                    <img
                      className="w-100 blog-img mt-1"
                      src="img/img-2.jpg"
                      alt="img-1"
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="blog-1">
                <div className="blog-content">
                  <div className="d-flex align-items-center gap-16">
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector "
                        src="img/calender.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">12 January, 2024</p>
                    </div>
                    <div className="d-flex align-items-center gap-12">
                      <img
                        className="calendar-vector "
                        src="img/user.png"
                        alt=""
                      />
                      <p className="fw-500 mt-3">Emilia Clove</p>
                    </div>
                  </div>
                  <div className="">
                    <a
                      href="blog-detail.html"
                      className="h5 fw-600 hover-content mb-20"
                    >
                      The Shift Toward Tailored Tech Experiences
                    </a>
                    <p className='mt-2 mb-0' >
                      Personalized by AI: Technology for Your Needs and Preferences
                    </p>
                  </div>
                </div>

                <div className="img-content">
                  <div className="blog-shape">
                    <img
                      className="shape"
                      src="img/blog-shape.png"
                      alt="shape"
                    />
                    <button className="blog-btn">
                      <span className="btn-text">Read More</span>
                    </button>
                  </div>
                  <a href="blog-detail.html" className="img-wrapper w-100">
                    <img
                      className="w-100 blog-img mt-1"
                      src="img/img-3.jpg"
                      alt="img-1"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*  blog-sec section end */}


      {/*  blog-sec section start */}

      <section className="project-sec py-80">
        <div className="container">

          <div className="heading mb-24">
            <h5 className="color-sec fw-600 mb-8">LET US SHOW YOU!</h5>
            <img className="title-icon" src="assets/media/vector/title-vector.png" alt=""/>
              <h2 className="light-black">WE DESIGN NOT
                JUST<br/> QUICK<span className="color-ter">IMPRESSIONS</span> </h2>
          </div>

          <div className="tab-content">
            <div className="row row-gap-4 align-items-center">
              <div className="col-lg-6">
                <div className="img-block">
                  <img src="img/project-image-1.jpg" alt=""/>
                    <div className="img-content">
                      <a href="project-detail.html" className="label-block">EduPro - Online learning
                        management system</a>
                    </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="img-block">
                  <img src="img/project-image-5.jpg" alt=""/>
                    <div className="img-content">
                      <a href="project-detail.html" className="label-block">EcoShop - Eco-friendly e-commerce
                        store</a>
                    </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="img-block">
                  <img src="img/project-image-2.jpg" alt=""/>
                    <div className="img-content">
                      <a href="project-detail.html" className="label-block">EventHive - Event management
                        platform</a>
                    </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="img-block">
                  <img src="img/project-image-4.jpg" alt=""/>
                    <div className="img-content">
                      <a href="project-detail.html" className="label-block">PulseHealth -Marketing for
                        healthcare</a>
                    </div>
                </div>
              </div>

              <div className="col-lg-12">
                <div className="img-block">
                  <img src="img/project-image-3.jpg" alt=""/>
                    <div className="img-content">
                      <a href="project-detail.html" className="label-block">RevampX - E-commerce website
                        transformation</a>
                    </div>
                </div>
              </div>

            

            </div>
          </div>
        </div>
      </section>



      {/*  blog-sec section end */}



      {/* contact-sec section start */}

      <section className="contact-sec py-80">
        <div className="container">
          <div className="row row-gap-4 align-items-center">
            <div className="col-lg-6">
              <div className="content-block">
                <img className="block-vector" src="img/contact-vector.png" alt="" />
                <h3 className="title-text mb-36">We’d love to <br /> help <span className="stroke-text">YOU!</span></h3>
                <p className="light-black mb-36 fs-6">We're a full service agency with experts ready to help. We'll get in touch
                  within 24 hours.</p>
                <div className="mb-36">
                  <h5 className="fw-500 mb-20 color-4">Contact Details</h5>
                  <div className="d-flex gap-12 align-items-center mb-12">
                    <div className="mobile-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_11612_3384)">
                          <path d="M16.8907 0H7.10933C5.89287 0 4.9032 0.989676 4.9032 2.20613V21.7938C4.9032 23.0103 5.89287 24 7.10933 24H16.8907C18.1071 24 19.0968 23.0103 19.0968 21.7938V2.20613C19.0968 0.989676 18.1071 0 16.8907 0ZM14.1576 1.40626L13.8311 2.6252H10.1689L9.84239 1.40626H14.1576ZM17.6906 21.7939C17.6906 22.2349 17.3317 22.5937 16.8907 22.5937H7.10933C6.66828 22.5937 6.30945 22.2349 6.30945 21.7939V2.20613C6.30945 1.76509 6.66828 1.40626 7.10933 1.40626H8.38659L9.08976 4.03145H14.9103L15.6134 1.40626H16.8907C17.3317 1.40626 17.6906 1.76509 17.6906 2.20613V21.7939Z" fill="#141516"></path>
                          <path d="M10.1719 19.7812H13.8281V21.1875H10.1719V19.7812Z" fill="#141516"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_11612_3384">
                            <rect width={24} height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <a href="tel:+12561244927" className="hover-content text-18">(+1) 256 124 4927</a>
                  </div>
                  <div className="d-flex gap-12 align-items-center">
                    <div className="mobile-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_11612_3390)">
                          <path d="M11.415 23.6869C11.5454 23.8825 11.7649 24 12 24C12.2351 24 12.4546 23.8825 12.585 23.6869C14.2491 21.1909 16.7 18.1084 18.408 14.9733C19.7736 12.4666 20.4375 10.3287 20.4375 8.4375C20.4375 3.78506 16.6524 0 12 0C7.34756 0 3.5625 3.78506 3.5625 8.4375C3.5625 10.3287 4.22639 12.4666 5.59205 14.9733C7.29872 18.106 9.75436 21.1961 11.415 23.6869ZM12 1.40625C15.877 1.40625 19.0312 4.56047 19.0312 8.4375C19.0312 10.0877 18.4234 12.0055 17.1731 14.3006C15.7009 17.0029 13.6 19.7441 12 22.0485C10.4002 19.7444 8.29922 17.003 6.82692 14.3006C5.57658 12.0055 4.96875 10.0877 4.96875 8.4375C4.96875 4.56047 8.12297 1.40625 12 1.40625Z" fill="#141516"></path>
                          <path d="M12 12.6562C14.3262 12.6562 16.2188 10.7637 16.2188 8.4375C16.2188 6.11128 14.3262 4.21875 12 4.21875C9.67378 4.21875 7.78125 6.11128 7.78125 8.4375C7.78125 10.7637 9.67378 12.6562 12 12.6562ZM12 5.625C13.5508 5.625 14.8125 6.88669 14.8125 8.4375C14.8125 9.98831 13.5508 11.25 12 11.25C10.4492 11.25 9.1875 9.98831 9.1875 8.4375C9.1875 6.88669 10.4492 5.625 12 5.625Z" fill="#141516"></path>
                        </g>
                        <defs>
                          <clipPath id="clip0_11612_3390">
                            <rect width={24} height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <p className="fs-6 mt-3 float-start">457 Main Boulevard, Michigan, USA</p>
                  </div>
                </div>
                <div className="mb-36">
                  <h5 className="fw-500 mb-20 color-4 fw-bold">Sales</h5>
                  <div className="d-flex gap-12 align-items-center mb-12">
                    <div className="mobile-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_11612_3399)">
                          <path d="M24 5.97733V5.59862C24 4.17272 22.8399 3.0127 21.4141 3.0127H2.58593C1.16002 3.0127 0 4.17272 0 5.59862V5.97733V5.98009V18.4018C0 19.8276 1.16002 20.9876 2.58593 20.9876H21.4142C22.84 20.9876 24 19.8276 24 18.4018V5.98009C24 5.9792 24 5.97827 24 5.97733ZM2.58593 4.41896H21.4141C22.0645 4.41896 22.5937 4.94813 22.5937 5.59862V5.59895L13.1767 11.877C12.5498 12.2949 11.4502 12.2948 10.8235 11.877L1.40626 5.5989V5.59857C1.40626 4.94813 1.93544 4.41896 2.58593 4.41896ZM21.4142 19.5814H2.58593C1.93544 19.5814 1.40626 19.0522 1.40626 18.4018V7.28899L10.0435 13.0471C10.5921 13.4128 11.2959 13.5957 12 13.5957C12.704 13.5957 13.4081 13.4128 13.9567 13.0471L22.5938 7.28899V18.4018C22.5937 19.0522 22.0646 19.5814 21.4142 19.5814Z" fill="#141516"></path>
                        </g>
                        <defs>
                          <clipPath>
                            <rect width={24} height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <a href="mailto:sales@dixx.com" className="hover-content  text-18">Sales@dixx.com</a>
                  </div>
                  <div className="d-flex gap-12 align-items-center">
                    <div className="mobile-svg">
                      <svg xmlns="http://www.w3.org/2000/svg" width={24} height="24" viewBox="0 0 24 24" fill="none">
                        <g clipPath="url(#clip0_11612_3399)">
                          <path d="M24 5.97733V5.59862C24 4.17272 22.8399 3.0127 21.4141 3.0127H2.58593C1.16002 3.0127 0 4.17272 0 5.59862V5.97733V5.98009V18.4018C0 19.8276 1.16002 20.9876 2.58593 20.9876H21.4142C22.84 20.9876 24 19.8276 24 18.4018V5.98009C24 5.9792 24 5.97827 24 5.97733ZM2.58593 4.41896H21.4141C22.0645 4.41896 22.5937 4.94813 22.5937 5.59862V5.59895L13.1767 11.877C12.5498 12.2949 11.4502 12.2948 10.8235 11.877L1.40626 5.5989V5.59857C1.40626 4.94813 1.93544 4.41896 2.58593 4.41896ZM21.4142 19.5814H2.58593C1.93544 19.5814 1.40626 19.0522 1.40626 18.4018V7.28899L10.0435 13.0471C10.5921 13.4128 11.2959 13.5957 12 13.5957C12.704 13.5957 13.4081 13.4128 13.9567 13.0471L22.5938 7.28899V18.4018C22.5937 19.0522 22.0646 19.5814 21.4142 19.5814Z" fill="#141516"></path>
                        </g>
                        <defs>
                          <clipPath>
                            <rect width={24} height="24" fill="white"></rect>
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                    <a href="mailto:marketing@dixx.com" className="hover-content  text-18">Marketing@dixx.com</a>
                  </div>
                </div>
                <h5 className="fw-500 mb-20 color-4 fw-bold">Quotes</h5>
                <div className="d-flex gap-12 align-items-center">
                  <div className="mobile-svg">
                    <svg xmlns="http://www.w3.org/2000/svg" width={24} height="24" viewBox="0 0 24 24" fill="none">
                      <g clipPath="url(#clip0_11612_3399)">
                        <path d="M24 5.97733V5.59862C24 4.17272 22.8399 3.0127 21.4141 3.0127H2.58593C1.16002 3.0127 0 4.17272 0 5.59862V5.97733V5.98009V18.4018C0 19.8276 1.16002 20.9876 2.58593 20.9876H21.4142C22.84 20.9876 24 19.8276 24 18.4018V5.98009C24 5.9792 24 5.97827 24 5.97733ZM2.58593 4.41896H21.4141C22.0645 4.41896 22.5937 4.94813 22.5937 5.59862V5.59895L13.1767 11.877C12.5498 12.2949 11.4502 12.2948 10.8235 11.877L1.40626 5.5989V5.59857C1.40626 4.94813 1.93544 4.41896 2.58593 4.41896ZM21.4142 19.5814H2.58593C1.93544 19.5814 1.40626 19.0522 1.40626 18.4018V7.28899L10.0435 13.0471C10.5921 13.4128 11.2959 13.5957 12 13.5957C12.704 13.5957 13.4081 13.4128 13.9567 13.0471L22.5938 7.28899V18.4018C22.5937 19.0522 22.0646 19.5814 21.4142 19.5814Z" fill="#141516"></path>
                      </g>
                      <defs>
                        <clipPath>
                          <rect width={24} height="24" fill="white"></rect>
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <a href="mailto:quotes@dixx.com" className="hover-content  text-18">Quotes@dixx.com</a>
                </div>
              </div>
            </div>



            <div className="col-lg-6 ">
              <div className="form-wrapper">
                <form>
                  <div className="mb-24">
                    <input type="text" name="fname" id="name" className="form-control" placeholder="First Name" />
                  </div>
                  <div className="mb-24">
                    <input type="text" name="fname" id="lname" className="form-control" placeholder="Last Name" />
                  </div>
                  <div className="mb-24">
                    <input type="email" name="email" id="email" className="form-control" placeholder="Email" />
                  </div>
                  <div className="mb-24">
                    <input type="tel" name="tel" id="tel" className="form-control" placeholder="Phone Number" />
                  </div>
                  <div className="mb-48">
                    <textarea name="message" id="message" placeholder="Leave us a message..." className="form-control"></textarea>
                  </div>
                  <div className="text-end">
                    <button type="submit" className="cus-btn">
                      <span className="btn-text">Submit Now</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* contact-sec section end */}
    </>
  )
}

export default Home